/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-expressions */
import PlaceIcon from "@mui/icons-material/Place";
import { Box, Checkbox, Typography } from "@mui/material";
import { useGlobalContext } from "context/globalContext";
import { RoleGuard } from "guards";
import Translations from "layouts/components/Translations";
import { Roles } from "models";
import { LocBulkActions } from "pages/locations/components";
import { useLocationListContext } from "../../../context";

export default function TextHeaderLoc({ onSearch, isDashboard }: { onSearch: Function; isDashboard?: boolean }) {
  //** On responsive view this header appear on org details */
  const { locationsMapListArray, selectedClaim, setSelectedClaim } = useLocationListContext();
  const { locEditMode } = useGlobalContext();

  //**Redux */
  const numSelected = selectedClaim.length;
  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = locationsMapListArray.map((n) => ({ id: n.id, isHQ: n.isHeadQuarter }));
      setSelectedClaim(newSelected);
      return;
    }
    setSelectedClaim([]);
  };

  const rowCount = locationsMapListArray.length;

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" gap={4} mt={locEditMode && isDashboard ? 0 : 3} mb={1} alignItems="flex-start">
      <Box>
        {isDashboard ? (
          <Box display="flex">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
            />
            <Typography variant="h1" fontWeight="500" mt={3} mb={3}>
              <Translations text="GLOBAL-TITLE-LOCATIONS" />
            </Typography>
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <PlaceIcon sx={{ fontSize: 24, mr: 4 }} />
            <Typography variant="h1" fontWeight="500">
              <Translations text={isDashboard ? "GLOBAL-TITLE-LOCATIONS" : "GLOBAL-TITLE-ALL-LOCATIONS"} />
            </Typography>
          </Box>
        )}

        {locEditMode && (
          <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner, Roles.organizationEditor]}>
            <Box display="flex" alignItems="center" mt={2} ml={-4}>
              <Box display="flex" alignItems="center">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                />
              </Box>
              <Box>
                <LocBulkActions onSearch={onSearch} hasSelectedItems={selectedClaim.length > 0} />
              </Box>
            </Box>
          </RoleGuard>
        )}
      </Box>
    </Box>
  );
}
