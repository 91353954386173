/* eslint-disable no-nested-ternary */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useState } from "react";
import { ModalConfirm } from "components";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { IBulkOrganization, IOrganizationListDOM } from "pages/organizations/models";
import { useFetchAndLoad, useToastMui } from "hooks";
import { bulkOrganizationAPI, useOrganizationListContext } from "pages/organizations/pages";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDeleteOrganizations } from "pages/organizations/hooks";
import { RoleGuard } from "guards";
import { PrivateRoutes, Roles } from "models";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import BulkOrgMoreOptions from "../../molecules/BulkOrgMoreOptions/BulkOrgMoreOptions";

enum BulkActions {
  ACTIVE = "ACTIVE",
  SUSPEND = "SUSPEND",
  FREC_WEEK = "FREC_WEEK",
  FREC_MOUNTH = "FREC_MOUNTH",
}

interface IBulk {
  is_suspended?: boolean;
  notification_time?: number;
  action?: BulkActions;
}

export default function OrgBulkActions({ onSearch, id, isActive }: { onSearch: Function; id: string; isActive: boolean }) {
  //** Context */
  const { selected, setSelected } = useOrganizationListContext();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [bulkObject, setBulkObject] = useState<IBulk>({});
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const { organizationsArray, setOrganizationsArray } = useOrganizationListContext();
  const [tempState, setTempState] = useState<IOrganizationListDOM[]>([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //** Hooks */
  const { callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deleteOrganizations } = useDeleteOrganizations();
  const handleCloseDelete = () => setOpenModalDelete(false);
  //** Function */
  const handleBulk = async () => {
    try {
      //** Save old state with optimistic update*/
      setTempState(organizationsArray);

      //** update loaded elements */
      const orgIndex = organizationsArray.findIndex((item) => item.id === id);
      setOrganizationsArray((prev) => {
        const orgCopy = [...prev];
        orgCopy[orgIndex] = { ...prev[orgIndex], isSuspended: bulkObject.is_suspended ?? prev[orgIndex].isSuspended };
        return [...orgCopy];
      });

      const body: IBulkOrganization = { ...bulkObject, item_id: selected };
      setOpenModal(false);
      await callEndpoint(bulkOrganizationAPI({ body }), () => handleCreateToast(t("ALERT-ERROR-UPDATE"), "error"));
      handleCreateToast(t("ALERT-SUCCESS-UPDATE"), "success");
      setSelected([]);
    } catch (error) {
      console.log(error);
      setOrganizationsArray(tempState);
    }
  };
  // Handle  dialog
  const handleClickOpen = (bulkQuery: IBulk) => {
    setOpenModal(true);
    setBulkObject(bulkQuery);
    handleClose();
  };
  const handleCloseModal = () => setOpenModal(false);

  const modalTitle =
    bulkObject.action === BulkActions.ACTIVE
      ? t("BULK-STATUS-ORG-POPUP.TITLE-2")
      : bulkObject.action === BulkActions.SUSPEND
      ? t("BULK-STATUS-ORG-POPUP.TITLE")
      : `Change the notification frequency of ${selected.length} organizations?`;

  const modalContent =
    bulkObject.action === BulkActions.ACTIVE
      ? t("BULK-STATUS-ORG-POPUP")
      : bulkObject.action === BulkActions.SUSPEND
      ? t("BULK-STATUS-2-ORG-POPUP")
      : bulkObject.action === BulkActions.FREC_WEEK
      ? t("BULK-FREC-ORG-POPUP")
      : t("BULK-FREC-2-ORG-POPUP");

  const handleChangeActive = (isActive: boolean) => {
    const action = isActive ? BulkActions.ACTIVE : BulkActions.SUSPEND;
    handleClickOpen({ is_suspended: !isActive, action });
  };

  return (
    <Box onClick={(e) => e.stopPropagation()} display="inline-block">
      <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner]}>
        <IconButton
          color="primary"
          aria-label="edit-organization"
          sx={{
            ml: 2,
            "&:focus": {
              outline: "0px auto -webkit-focus-ring-color",
            },
          }}
          onClick={(e) => {
            handleClick(e);
            setSelected([id]);
          }}
        >
          <MoreVertIcon sx={{ fontSize: "2.5rem" }} color="secondary" />
        </IconButton>
      </RoleGuard>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner]}>
          <MenuItem
            onClick={() => {
              navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.EDIT_NAV}/${id}`);
              handleClose();
            }}
          >
            <Typography variant="h3">{t("GLOBAL-BULK-ACTIONS-EDIT")}</Typography>
          </MenuItem>
        </RoleGuard>
        <RoleGuard authorizedRoles={[Roles.superAdmin]}>
          <MenuItem
            onClick={() => {
              setOpenModalDelete(true);
              handleClose();
            }}
          >
            {t("ICON-DELETE")}
          </MenuItem>
        </RoleGuard>
        {!isActive ? (
          <MenuItem
            onClick={() => {
              handleChangeActive(false);
              handleClose();
            }}
          >
            <Typography variant="h3">{t("GLOBAL-BULK-ACTIONS-SUSPEND")}</Typography>
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              handleChangeActive(true);
              handleClose();
            }}
          >
            <Typography variant="h3">{t("GLOBAL-BULK-ACTIONS-ACTIVE")}</Typography>
          </MenuItem>
        )}
        <BulkOrgMoreOptions
          hasSelectedItems
          handleOption1={() => handleClickOpen({ notification_time: 7, action: BulkActions.FREC_WEEK })}
          handleOption2={() => handleClickOpen({ notification_time: 30, action: BulkActions.FREC_MOUNTH })}
        />
      </Menu>
      <Box display="flex" alignItems="center">
        <ModalConfirm
          open={openModal}
          title={modalTitle}
          description={modalContent}
          textButtonConfirm={t("BUTTON-ACCEPT")}
          handleClose={handleCloseModal}
          handleConfirm={handleBulk}
          sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
          sxTitle={{ fontSize: "2rem !important" }}
        />
        <ModalConfirm
          open={openModalDelete}
          title={`Delete ${selected.length} organizations?`}
          description={t("ORGANIZATIONS-DELETE-MODAL-CONFIRM-DESCRIPTION")}
          textButtonConfirm={t("BUTTON-ACCEPT")}
          handleClose={handleCloseDelete}
          handleConfirm={() => {
            deleteOrganizations(selected);
            setOpenModalDelete(false);
            onSearch();
          }}
          sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
          sxTitle={{ fontSize: "2rem !important" }}
        />
      </Box>
    </Box>
  );
}
