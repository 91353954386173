import UserTableResponsive from "pages/users/components/responsive/UserTableResponsive/UserTableResponsive";
import { useGlobalContext } from "context/globalContext";
import { useEffect } from "react";
import { Box, Fade, Theme, useMediaQuery } from "@mui/material";
import { RoleGuard } from "guards";
import { useLocation } from "react-router-dom";
import { PrivateRoutes, Roles } from "models";
import { UsersSpeedDial, UsersSpeedDialOnScroll } from "components/responsiveComponents";
import { UsersListContextProvider } from "./context";
import UsersTable from "./components/molecules/UsersTable/UsersTable";

export default function UsersList({
  organizationId,
  embebed,
  locationId,
  staticTop,
  height,
  invertBg,
  locationLanding,
}: {
  organizationId?: string;
  embebed?: boolean;
  locationId?: string;
  staticTop?: boolean;
  height?: string;
  invertBg?: boolean;
  locationLanding?: boolean;
}) {
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { setUserEditMode, userEditMode } = useGlobalContext();
  const location = useLocation();
  useEffect(() => {
    if (!embebed) setUserEditMode(false);
  }, []);
  return (
    <UsersListContextProvider>
      <Box>
        <Fade in timeout={500}>
          <Box>
            {!underMd && !embebed ? (
              <UsersTable organizationId={organizationId || ""} embebed={embebed} locationId={locationId || ""} />
            ) : (
              <UserTableResponsive
                organizationId={organizationId || ""}
                locationId={locationId || ""}
                embebed={embebed}
                staticTop={staticTop}
                height={height}
                invertBg={!!invertBg}
                locationLanding={locationLanding}
              />
            )}
          </Box>
        </Fade>

        {(!location.pathname.includes(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}`) && !underMd) ||
        location.pathname.includes(`/${PrivateRoutes.USERS}/${PrivateRoutes.LIST}`) ? (
          <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.organizationOwner, Roles.locationOwner, Roles.multiOrgOwner]}>
            <Fade in={underMd && userEditMode}>
              <Box position="relative">
                <UsersSpeedDial organizationId={organizationId || ""} embebed={embebed} locationId={locationId || ""} />
              </Box>
            </Fade>
          </RoleGuard>
        ) : (
          <>
            {userEditMode && (
              <UsersSpeedDialOnScroll organizationId={organizationId || ""} embebed={embebed} locationId={locationId || ""} />
            )}
          </>
        )}
      </Box>
    </UsersListContextProvider>
  );
}
