import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { OrganizationCard } from "pages/organizations/components/molecules/OrganizationCard";
import { PrivateRoutes } from "models";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { IOrganizationListDOM } from "pages/organizations/models";
import TableResponsiveRow from "../TableResponsiveRow/TableResponsiveRow";

export default function OrgListViewByType({
  organizationsArray,
  onSearch,
}: {
  organizationsArray: IOrganizationListDOM[];
  onSearch: Function;
}) {
  const { orgListViewType } = useListAndSearchContext();

  const navigate = useNavigate();
  return (
    <>
      {orgListViewType === "cards" ? (
        <Stack flexWrap="wrap" direction="row" gap={8} justifyContent="center" mt={8} width="100%">
          {organizationsArray?.map((item) => (
            <OrganizationCard
              id={item.id}
              onSearch={onSearch}
              logo={item.logo as string}
              name={item.name}
              url={item.hqInformation.website}
              phone={item.hqInformation.phone}
              email={item.hqInformation.email}
              address={item.hqInformation.address}
              active={item.isSuspended}
              handleClickCard={() => {
                navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${item.id}`);
              }}
            />
          ))}
        </Stack>
      ) : (
        <>
          {organizationsArray?.map((item, i) => (
            <TableResponsiveRow data={item} index={i} onSearch={onSearch} />
          ))}
        </>
      )}
    </>
  );
}
