import { useState } from "react";
// ** MUI Imports
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

import { Avatar, Box, IconButton, Skeleton, Typography } from "@mui/material";
//** Components */
import { Chip } from "components";
import { useTranslation } from "react-i18next";
//** Icons*/
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import Color from "color-thief-react";
import { PrivateRoutes } from "models";
import { useNavigate } from "react-router-dom";
import OrgBulkActions from "../../organism/OrgBulkActions/OrgBulkActions";

interface IOrgCard {
  logo: string;
  name: string;
  url: string;
  phone: string;
  email: string;
  address: string;
  active: boolean;
  id: string;
  onSearch: Function;
  handleClickCard: () => void;
  isDashboard?: boolean;
}
export default function OrganizationCard(props: IOrgCard) {
  //** States */
  const [load, setLoad] = useState(true);
  const { logo, name, url, phone, email, address, handleClickCard, active, onSearch, id, isDashboard } = props;
  //** Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();

  const animation = isDashboard
    ? {}
    : {
        transition: "transform 0.3s, border 0.3s",
        "&:hover": {
          transform: "scale(1.05)",
          border: "2px solid",
          borderColor: "primary.main",
        },
      };
  return (
    <Card
      onClick={handleClickCard}
      sx={{
        cursor: "pointer",
        width: isDashboard ? "100%" : "372px",
        minHeight: "300px",
        border: "2px solid",
        borderColor: "transparent",
        ...animation,
      }}
    >
      <CardHeader
        title=""
        avatar={
          <Color src={logo} crossOrigin="anonymous" format="hex">
            {({ data, loading }) => {
              if (loading) {
                return (
                  <Skeleton sx={{ display: load ? "block" : "none", ml: 4 }} animation="wave" variant="circular" width={60} height={60} />
                );
              }
              return (
                <Avatar
                  sx={{
                    display: load ? "none" : "block",
                    ml: 4,
                    width: 60,
                    height: 60,
                    backgroundColor: data || "transparent",
                    "& .MuiAvatar-img": { objectFit: "contain !important" },
                  }}
                  src={typeof logo === "string" ? logo : ""}
                  alt={name}
                  onLoad={(e) => {
                    if (e.type === "load") {
                      setLoad(false);
                    }
                  }}
                />
              );
            }}
          </Color>
        }
        action={
          <Box sx={{ display: "flex" }} alignItems="center" height="60px">
            <Box alignContent="flex-end">
              {active ? (
                <Chip size="small" label={t("OPTION-SELECT-1")} color="secondary" sx={{ fontSize: "1.4rem" }} />
              ) : (
                <Chip size="small" label={t("OPTION-SELECT-2")} color="success" sx={{ fontSize: "1.4rem" }} />
              )}

              {isDashboard ? (
                <IconButton
                  color="primary"
                  aria-label="edit-organization"
                  sx={{
                    ml: 2,
                    "&:focus": {
                      outline: "0px auto -webkit-focus-ring-color",
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.EDIT_NAV}/${id}`);
                  }}
                >
                  <ModeEditOutlineOutlinedIcon sx={{ fontSize: "2rem" }} color="secondary" />
                </IconButton>
              ) : (
                <OrgBulkActions onSearch={onSearch} id={id} isActive={active} />
              )}
            </Box>
          </Box>
        }
      />
      <CardContent sx={{ mx: 1, mt: 4 }}>
        <Box px={4} mb={4}>
          <Typography variant="h2" mb={2} fontWeight="700">
            {name}
          </Typography>
          <Typography variant="h5" color="textSecondary" sx={{ height: "2.4rem", cursor: "pointer" }} noWrap>
            {url}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <IconButton
            onClick={() => {}}
            color="primary"
            aria-label="edit-organization"
            sx={{
              "&:focus": {
                outline: "0px auto -webkit-focus-ring-color",
              },
            }}
          >
            <LocalPhoneOutlinedIcon sx={{ fontSize: "2rem" }} color="secondary" />
          </IconButton>
          <Typography variant="h5" color="textSecondary">
            {phone}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <IconButton
            onClick={() => {}}
            color="primary"
            aria-label="edit-organization"
            sx={{
              "&:focus": {
                outline: "0px auto -webkit-focus-ring-color",
              },
            }}
          >
            <EmailOutlinedIcon sx={{ fontSize: "2rem" }} color="secondary" />
          </IconButton>
          <Typography variant="h5" color="textSecondary">
            {email}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <IconButton
            onClick={() => {}}
            color="primary"
            aria-label="edit-organization"
            sx={{
              "&:focus": {
                outline: "0px auto -webkit-focus-ring-color",
              },
            }}
          >
            <LocationOnOutlinedIcon sx={{ fontSize: "2rem" }} color="secondary" />
          </IconButton>
          <Typography variant="h5" color="textSecondary">
            {address}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
