/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useRoleGuard } from "hooks";
import useLocTables from "hooks/useLocTables";
import { ILocationsListDOM } from "pages/locations/models";
import { IDropdownOption, PrivateRoutes, Roles } from "models";
import { Chip, Image, TooltipError } from "components";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "context/globalContext";
import WarningTooltip from "components/molecules/WarningTooltip/WarningTooltip";
import notImage from "assets/NotImage.png";
import { useLocationListContext } from "../../../context";

export default function TableResponsiveRow({
  data,
  index,
  purposeOptions,
  onClick,
  embebed,
}: {
  data: ILocationsListDOM;
  index: number;
  purposeOptions: IDropdownOption[];
  onClick?: () => void;
  embebed?: boolean;
}) {
  const [expandPurposes, setExpandPurposes] = useState(false);
  const [dobleClickId, setDobleClickId] = useState<string>("");
  const navigate = useNavigate();
  const { locEditMode } = useGlobalContext();
  const {
    locationsMapListArray,
    selectedClaim,
    setActiveLocationItemList,
    activeLocationItemList,
    statusFocus,
    clickedMarker,
    setClickedMarker,
  } = useLocationListContext();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { setSelectedClaim, load, setLoad } = useLocationListContext();
  const { isSelected, handleClickTableCheckbox } = useLocTables(setSelectedClaim, selectedClaim, locationsMapListArray);

  const locPurposes = purposeOptions.filter((item) => data.purposeCodes?.includes(`${item.code}`));

  const isAuthorized = useRoleGuard([
    Roles.superAdmin,
    Roles.multiOrgOwner,
    Roles.organizationEditor,
    Roles.organizationOwner,
    Roles.locationEditor,
    Roles.locationOwner,
  ]);
  const isItemSelected = isSelected(data.id);
  //Table logic to navigate
  const handleNavigate = (id: string) => {
    navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${id}`, { replace: false });
  };

  const handleChatClick = (type: "location", id: string, coordinates: [number, number]) => {
    setActiveLocationItemList({ type, id, coordinates });
  };

  const activeCondition =
    activeLocationItemList !== null && activeLocationItemList.id === data.id && activeLocationItemList.type === "location";

  useEffect(() => {
    if (clickedMarker) {
      statusFocus?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
    setClickedMarker(null);
  }, [clickedMarker]);

  return (
    <ListItem sx={{ px: 0, py: 0 }} ref={`${clickedMarker}` === data.id ? statusFocus : null} key={`org-${data.id}`}>
      <ListItemButton
        role={undefined}
        onMouseEnter={() => handleChatClick("location", data.id, data.address.coordinates)}
        onClick={() => {
          if (locEditMode) {
            return handleClickTableCheckbox(data?.id, data?.isHeadQuarter);
          }
          if (onClick && dobleClickId !== data.id) {
            onClick();
            //** Activates redirect when doble click the row */
            setDobleClickId(data.id);
          } else handleNavigate(data.id);
        }}
        sx={{
          px: 0,
          backgroundColor: index % 2 === 0 ? "background.paper" : "background.default",
          display: "flex",
          alignItems: "flex-start",
          ...((activeCondition || isItemSelected) &&
            !locEditMode && {
              backgroundColor: (theme) => `${isItemSelected ? "" : theme.palette.divider} !important`,
            }),
          minHeight: "100px",
        }}
      >
        <ListItemAvatar sx={{ mr: 4, height: "100%" }}>
          <Box display="flex">
            <Box display="flex" onClick={(e) => e.stopPropagation()} pl={locEditMode ? 0 : 8}>
              {isAuthorized && locEditMode && (
                <Checkbox
                  onClick={() => {
                    handleClickTableCheckbox(data?.id, data?.isHeadQuarter);
                  }}
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{
                    "aria-labelledby": data.id,
                  }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, py: 0, px: 2 }}
                />
              )}
            </Box>
          </Box>
        </ListItemAvatar>
        <Box
          width={locEditMode ? "calc(100% - 75px)" : "calc(100% - 30px)"}
          pr={locEditMode ? 0 : 8.5}
          display="flex"
          justifyContent="space-between"
        >
          <Box display="flex" justifyContent="space-between" width={underMd ? "calc(100% - 100px)" : "calc(100% - 95px)"}>
            <Box flex={underMd ? 2 : ""} width={underMd ? "92%" : "100%"}>
              <Box display="flex" alignItems="flex-start">
                <Typography
                  variant="h3"
                  fontWeight="700"
                  mb={2}
                  pt={1}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  alignItems="center"
                  mr={4}
                >
                  {data.name.toUpperCase()}
                </Typography>
              </Box>
              <Typography variant="h3" mb={4} sx={{ width: underMd ? "90%" : "100%" }}>
                {data.address.address1}
              </Typography>

              <Box display="flex" alignItems="center" flexWrap="wrap" gap={2}>
                {locPurposes.map((item, i) => (
                  <>{i >= 2 && !expandPurposes ? <></> : <Chip label={`${item.label}`?.toUpperCase()} size="small" />}</>
                ))}
                {locPurposes.length > 2 && !expandPurposes && (
                  <Button
                    variant="text"
                    color="secondary"
                    sx={{ px: 0, minWidth: 40 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpandPurposes(true);
                    }}
                  >
                    +{locPurposes.length - 2}
                  </Button>
                )}
              </Box>
            </Box>
            <Box mr={underMd ? 0 : 2}>
              {data.warnings && data.warnings.length > 0 && <WarningTooltip table />}
              {!data.hasRequiredFields && <TooltipError />}
            </Box>
          </Box>
          <Box
            flex={underMd ? 1 : ""}
            display="flex"
            justifyContent="space-between"
            alignItems={underMd ? "flex-end" : "space-between"}
            flexDirection="column"
          >
            <Box justifyContent="flex-end" display="flex">
              <Badge
                color={data.isVisible ? "success" : "secondary"}
                badgeContent=" "
                sx={{
                  "& .MuiBadge-badge": {
                    top: 12,
                    boxShadow: (theme) => `0 0 0 2px ${theme.palette.background.paper}`,
                    height: 14,
                    minWidth: 14,
                  },
                  fontSize: "10px",
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Box
                  justifyContent="flex-end"
                  sx={{
                    display: "flex",
                    flexDirection: { sm: "row" },
                    alignItems: { xs: "flex-start", sm: "center" },
                    gap: { xs: 2, sm: 0 },
                  }}
                >
                  {data.isHeadQuarter && <Chip label="HQ" size="small" color="success" sx={{ mr: 2 }} />}
                  <Chip label={data.marketOrCountry} size="small" sx={{ mr: 5 }} />
                </Box>
              </Badge>
            </Box>
            {!embebed && (
              <Box display="flex" justifyContent="flex-end" mt={2}>
                {data.organizationData?.logo ? (
                  <>
                    <Box>
                      <Image
                        src={typeof data.organizationData?.logo === "string" ? data?.organizationData?.logo : ""}
                        alt={`logo-${data.id}`}
                        name={`logo-${data.id}`}
                        onLoad={(e: any) => {
                          if (e.type === "load") {
                            setLoad((prev: any) => {
                              const copy = prev;
                              copy[index] = false;
                              return [...copy];
                            });
                          }
                        }}
                        style={{ objectFit: "fill", width: "80px", display: load[index] ? "none" : "block" }}
                      />
                    </Box>
                    <Skeleton
                      sx={{ display: load[index] ? "block" : "none" }}
                      animation="wave"
                      variant="rectangular"
                      width={80}
                      height={30}
                    />
                  </>
                ) : (
                  <Box display="flex" alignItems="center" justifyContent="flex-end">
                    <img src={notImage} alt="logo" width={50} />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
