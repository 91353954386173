import { Box, MenuItem, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function BulkOrgMoreOptions({
  handleOption1,
  handleOption2,
  hasSelectedItems,
}: {
  handleOption1: () => void;
  handleOption2: () => void;
  hasSelectedItems: boolean;
}) {
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorEl2);

  const { t } = useTranslation();

  const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  return (
    <MenuItem
      id="demo-positioned-button"
      aria-controls={open2 ? "demo-positioned-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open2 ? "true" : undefined}
    >
      <Box onClick={handleClick2} display="flex" alignItems="center" width="100%">
        <Typography variant="h3">{t("BULK-FREC-MENU")}</Typography>
      </Box>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl2}
        open={open2}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          disabled={!hasSelectedItems}
          onClick={() => {
            handleClose2();
            handleOption1();
          }}
        >
          {t("BULK-FREC-SUB-MENU-1")}
        </MenuItem>
        <MenuItem
          disabled={!hasSelectedItems}
          onClick={() => {
            handleClose2();
            handleOption2();
          }}
        >
          {t("BULK-FREC-SUB-MENU-2")}
        </MenuItem>
      </Menu>
    </MenuItem>
  );
}
