import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, IconButton, Skeleton, Theme, Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { RoleGuard } from "guards";
import { PrivateRoutes, Roles } from "models";
import { GlobalProvider } from "pages/locations";
import LocationDashboardViewWithCall from "pages/locations/components/organism/locationDashboardView/LocationDashboardViewWithCall";
import { OrganizationCard } from "pages/organizations/components/molecules/OrganizationCard";
import { useOrganizationContext } from "pages/organizations/context";

import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppStore } from "redux/store";

import UserDashboardView from "pages/users/components/organism/UserDashboardView";

import { useRoleGuard } from "hooks";
import { useOrganizationInfoContext } from "../../context/OrganizationInfoContext";
import useOrganization from "../../hooks/useOrganization";

export default function OrganizationDashboard() {
  //**Context */
  const { logoText, name, url, phoneOrganization, emailOrganization, cityOrganization } = useOrganizationContext();
  const { active } = useOrganizationInfoContext();

  //**Hooks */
  const params = useParams();
  const navigate = useNavigate();
  const adminRoles = [Roles.superAdmin, Roles.multiOrgOwner, Roles.generalViewOnly];
  const isAdmin = useRoleGuard(adminRoles);
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  //** Redux */
  const userState = useSelector((store: AppStore) => store.user);
  const { organization } = userState;

  const orgId = params.id || `${organization?.id}`;

  //** Data get information */
  const { loading } = useOrganization(orgId);

  return (
    <GlobalProvider>
      <Box mb={underMd ? 32 : 0}>
        {/* TODO  Change for component GobackBreadcrumb*/}
        <RoleGuard authorizedRoles={adminRoles}>
          <Box
            display="flex"
            alignItems="center"
            my={8}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`);
            }}
          >
            <IconButton sx={{ mr: 2 }}>
              <ArrowBackIosNewIcon sx={{ fontSize: 16 }} />
            </IconButton>
            <Typography variant="h3">Go Back to Dashboard</Typography>
          </Box>
        </RoleGuard>
        {/* END TODO  Change for component GobackBreadcrumb*/}
        <Grid container spacing={12} mx={0} mt={isAdmin ? 0 : 12}>
          <Grid xs={12} md={4} lg={3}>
            {loading || !name ? (
              <Skeleton height="300px" variant="rounded" />
            ) : (
              <OrganizationCard
                id={orgId}
                onSearch={() => {}}
                logo={logoText}
                name={name}
                url={url}
                phone={phoneOrganization}
                email={`${emailOrganization || ""}`}
                address={cityOrganization}
                active={active}
                handleClickCard={() => {}}
                isDashboard
              />
            )}
            <UserDashboardView />
          </Grid>
          <Grid xs={12} md={8} lg={9} height="100%">
            <LocationDashboardViewWithCall isDashboard />
          </Grid>
        </Grid>
      </Box>
    </GlobalProvider>
  );
}
