/* eslint-disable no-shadow */
import { useEffect, useState } from "react";
//* MUI Imports
import { Box, Fade, Theme, useMediaQuery } from "@mui/material";
//* Redux
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
// ** Components
import { LocationDashboardContent, LocationDashboardList } from "pages/locations/components";
//** Hooks */
import { useParams } from "react-router-dom";
import { useSettings } from "hooks";
import { useLocationListContext } from "pages/locations/pages/locationsList";
import { useTheme } from "@emotion/react";
import useLocationClaims from "../../../hooks/useLocationClaims";

export default function LocationClaimView() {
  //* States
  const [leftSidebarOpen, setLeftSidebarOpen] = useState<boolean>(false);
  const [permanentLeftBar, setPermanentLeftBar] = useState<boolean>(true);

  // ** Hooks
  const theme = useTheme();
  const hidden = useMediaQuery("(min-width:1200px)");
  const { settings } = useSettings();
  const params = useParams();
  const { organizationId } = params;
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  // ** Vars
  const { skin } = settings;
  const smAbove = useMediaQuery("(min-width:1200px)"); //** Left bar over or half */
  const sidebarWidth = smAbove ? 450 : "100%";

  //** Redux */
  const userState = useSelector((store: AppStore) => store.user);
  const { organization } = userState;
  //** Hooks */
  const { loading, hasNextPage, page, loadMoreLocations } = useLocationClaims(organizationId || organization?.id);
  const { setSelectedClaim } = useLocationListContext();

  const claimViewHeight = "calc(100vh - 180px)";

  useEffect(() => {
    setSelectedClaim([]);
  }, []);
  const handleLeftSidebarToggle = () => setLeftSidebarOpen(!leftSidebarOpen);
  const handleChangeLeftBar = () => setPermanentLeftBar((prev: boolean) => !prev);

  return (
    <Fade in unmountOnExit timeout={500}>
      <Box
        sx={{
          marginTop: 0,
          pt: underMd ? 30 : 0,
          width: "100%",
          display: "flex",
          borderRadius: "1rem",
          overflow: "hidden",
          position: "relative",
          backgroundColor: "background.paper",
          boxShadow: skin === "bordered" ? 0 : 6,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          ...(skin === "bordered" && { border: `1px solid ${theme.palette.divider}` }),
        }}
      >
        <LocationDashboardList
          permanentLeftBar={smAbove ? permanentLeftBar : true}
          hidden={hidden}
          leftSidebarOpen={leftSidebarOpen}
          mdAbove={smAbove}
          sidebarWidth={sidebarWidth}
          handleLeftSidebarToggle={handleLeftSidebarToggle}
          loading={loading}
          hasNextPage={!!hasNextPage}
          page={page}
          loadMoreLocations={loadMoreLocations}
          claimView
        />
        <LocationDashboardContent
          hidden={hidden}
          permanentLeftBar={permanentLeftBar}
          handleChangeLeftBar={handleChangeLeftBar}
          smAbove={smAbove}
          claimView
          handleLeftSidebarToggle={handleLeftSidebarToggle}
          loading={loading}
          isListShow={permanentLeftBar}
          mapHeight={claimViewHeight}
        />
      </Box>
    </Fade>
  );
}
