//** Components imports */
import { LocationListContextProvider } from "pages/locations";

//** UTILS */

import OrganizationDashboard from "./components/organism/OrganizationDashboard";
import { OrganizationInfoContextProvider } from "./context/OrganizationInfoContext";

export default function OrganizationsInformation() {
  return (
    <OrganizationInfoContextProvider>
      <LocationListContextProvider>
        <OrganizationDashboard />
      </LocationListContextProvider>
    </OrganizationInfoContextProvider>
  );
}
