/* eslint-disable no-nested-ternary */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { useState } from "react";
import { ModalConfirm } from "components";
import { Box, IconButton, Switch, Theme, Tooltip } from "@mui/material";
import { useFetchAndLoad, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
import { IBulkUser } from "pages/users/models";
import { useUsersListContext } from "pages/users/pages/usersList/context";
import { bulkUserAPI } from "pages/users/services";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import useDeleteUsers from "pages/users/hooks/useDeleteUsers";
import { useListAndSearchContext } from "context/ListsAndSearchContext";

enum BulkActions {
  ACTIVE = "ACTIVE",
  SUSPEND = "SUSPEND",
  DELETE = "DELETE",
}

interface IBulk {
  is_suspended?: boolean;
  action?: BulkActions;
}

export default function UserBulkActions({ onSearch, hasSelectedItems }: { onSearch: Function; hasSelectedItems: boolean }) {
  //** Context */
  const { selected, setSelected } = useUsersListContext();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [bulkObject, setBulkObject] = useState<IBulk>({});
  const [activeCheck, setActiveCheck] = useState<boolean>(true);
  //** Hooks */
  const { callEndpoint, loading } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { setUserGetToggle } = useListAndSearchContext();
  const { t } = useTranslation();

  //** Function */
  const handleBulk = async () => {
    try {
      const body: IBulkUser = { ...bulkObject, item_id: selected };
      setOpenModal(false);
      await callEndpoint(bulkUserAPI({ body }), (err: any) => handleCreateToast(err.response.data.message, "error"));
      setSelected([]);
      setUserGetToggle((prev: boolean) => !prev);
      handleCreateToast(t("ALERT-SUCCESS-UPDATE"), "success");
    } catch (error) {
      console.log(error);
    }
  };
  const { deleteUsers } = useDeleteUsers();
  // Handle  dialog
  const handleClickOpen = (bulkQuery: IBulk) => {
    setOpenModal(true);
    setBulkObject(bulkQuery);
  };
  const handleClose = () => setOpenModal(false);
  const handleCloseDelete = () => setOpenModalDelete(false);

  const modalTitle = bulkObject.action === BulkActions.ACTIVE ? `Activate ${selected.length} users?` : `Suspend  ${selected.length} users?`;

  const modalContent = bulkObject.action === BulkActions.ACTIVE ? t("BULK-USER-ACTIVATE") : `${t("BULK-USER-SUSPEND")}`;

  const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    const action = event.target.checked ? BulkActions.ACTIVE : BulkActions.SUSPEND;
    handleClickOpen({ is_suspended: !event.target.checked, action });
    setActiveCheck(event.target.checked);
  };

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={t("ICON-DELETE")}>
        <IconButton onClick={() => setOpenModalDelete(true)} disabled={!hasSelectedItems}>
          <DeleteOutlineOutlinedIcon sx={{ fontSize: 22 }} color="error" />
        </IconButton>
      </Tooltip>
      <Switch checked={activeCheck} onChange={handleChangeActive} disabled={!hasSelectedItems} color="success" />

      <ModalConfirm
        open={openModal}
        title={modalTitle}
        description={modalContent}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleClose}
        handleConfirm={handleBulk}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
      <ModalConfirm
        open={openModalDelete}
        title={`Delete ${selected.length} users?`}
        description={t("USERS-DELETE-MODAL-CONFIRM-DESCRIPTION")}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleCloseDelete}
        handleConfirm={() => {
          deleteUsers(selected);
          setOpenModalDelete(false);
          onSearch();
        }}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
    </Box>
  );
}
