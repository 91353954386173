/* eslint-disable max-len */
//** MUI Imports */
import { Box, Card, Theme, useMediaQuery } from "@mui/material";
//** Components */
import { PrivateRoutes } from "models";
import { BasicInformation, OrganizationAddress, OrganizationContact } from "pages/organizations/components";
import { MapOrgView } from "pages/organizations/components/molecules/MapOrgView";
import EmailNotificationSection from "pages/organizations/pages/organizationsInformation/components/molecules/EmailNotificationSection/EmailNotificationSection";

//** Hooks */
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
//** Utils */
import { GoBackBreadcrumb, SectionsTitles } from "components";
import { BusinessOrg } from "../molecules/BusinessOrg";
import ButtonCreateOrganization from "../molecules/ButtonCreateOrganization/ButtonCreateOrganization";

export default function OrganizationCreateView() {
  //** Hooks */
  const { t } = useTranslation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();
  return (
    <Box mx={4} mb={underMd ? 12 : 0}>
      <GoBackBreadcrumb
        label={t("GO-BACK-ORGANIZATION-DASHBOARD")}
        onClick={() => {
          navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`);
        }}
      />
      <Card sx={{ py: 8, px: 6 }}>
        <SectionsTitles title={`${t("ORGANIZATION-DETAIL-TITLE")}`} subTitle={`${t("ORGANIZATION-DETAIL-SUBTITLE")}`} />
        <BasicInformation createView />
        <MapOrgView createView />
        <OrganizationAddress />
        <OrganizationContact createView />
        <BusinessOrg createView />
        <EmailNotificationSection createView />
        <Box mt={12} />
        <ButtonCreateOrganization />
      </Card>
    </Box>
  );
}
