/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
import { useState } from "react";
import { Avatar, Box, ListItem, ListItemAvatar, ListItemButton, Skeleton, Theme, Typography, useMediaQuery } from "@mui/material";
import { IOrganizationListDOM } from "pages/organizations/models";
import { PrivateRoutes } from "models";
import Color from "color-thief-react";
import { useNavigate } from "react-router-dom";
import { Chip } from "components";
import OrgBulkActions from "pages/organizations/components/organism/OrgBulkActions/OrgBulkActions";
import { useTranslation } from "react-i18next";
import { useOrganizationListContext } from "../../../context";

export default function TableResponsiveRow({
  data,
  index,
  onClick,
  onSearch,
}: {
  data: IOrganizationListDOM;
  index: number;
  onClick?: () => void;
  onSearch: Function;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setLoad, load } = useOrganizationListContext();
  const [dobleClickId, setDobleClickId] = useState<string>("");
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  //Table logic to navigate
  const handleNavigate = (id: string) => {
    navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${id}`, { replace: false });
  };

  return (
    <ListItem sx={{ px: 4, py: 0 }}>
      <ListItemButton
        role={undefined}
        onClick={() => {
          if (onClick && dobleClickId !== data.id) {
            onClick();
            //** Activates redirect when doble click the row */
            setDobleClickId(data.id);
          } else handleNavigate(data.id);
        }}
        sx={{
          pl: 6,
          pr: 4,
          backgroundColor: "background.paper",
          borderBottom: (theme: Theme) => `${theme.palette.divider} 1px solid`,

          display: "flex",
          alignItems: "center",
          height: !underMd ? "100px" : "auto",
          py: underMd ? 4 : 0,
        }}
      >
        <ListItemAvatar sx={{ mr: 8 }}>
          <Box display="flex">
            <Box mt={0.4}>
              <Color src={data.logo as string} crossOrigin="anonymous" format="hex">
                {({ data: dataImg, loading }) => {
                  return (
                    <>
                      <Skeleton
                        sx={{ display: load[index] || loading ? "block" : "none", ml: 4 }}
                        animation="wave"
                        variant="circular"
                        width={60}
                        height={60}
                      />

                      <Avatar
                        sx={{
                          display: load[index] ? "none" : "block",
                          ml: 4,
                          width: 60,
                          height: 60,
                          backgroundColor: dataImg || "transparent",
                          "& .MuiAvatar-img": { objectFit: "contain !important" },
                        }}
                        src={typeof data.logo === "string" ? data.logo : ""}
                        alt={data.name}
                        onLoad={(e: any) => {
                          if (e.type === "load") {
                            setLoad((prev: any) => {
                              const copy = prev;
                              copy[index] = false;
                              return [...copy];
                            });
                          }
                        }}
                      />
                    </>
                  );
                }}
              </Color>
            </Box>
          </Box>
        </ListItemAvatar>
        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
          <Box width={underMd ? "40vw" : "60vw"}>
            <Typography variant="h2" fontWeight="500" mb={2} sx={{ display: "flex", justifyContent: "space-between" }}>
              {data.name}
            </Typography>
            <Typography
              variant="h4"
              color="text.secondary"
              sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "80%" }}
            >
              {data.hqInformation.website || "------"}
            </Typography>
          </Box>
          <Box alignContent="flex-end" alignItems="center" display="flex">
            {underMd ? (
              <>
                {data.isSuspended ? (
                  <Box
                    bgcolor={(theme: Theme) => {
                      return theme.palette.mode === "dark" ? theme.palette.secondary.dark : theme.palette.secondary.light;
                    }}
                    borderRadius="50%"
                    width="12px"
                    height="12px"
                  />
                ) : (
                  <Box
                    bgcolor={(theme: Theme) => (theme.palette.mode === "dark" ? theme.palette.success.dark : theme.palette.success.light)}
                    borderRadius="50%"
                    width="12px"
                    height="12px"
                  />
                )}
              </>
            ) : (
              <>
                {data.isSuspended ? (
                  <Chip size="small" label={t("OPTION-SELECT-1")} color="secondary" sx={{ fontSize: "1.4rem" }} />
                ) : (
                  <Chip size="small" label={t("OPTION-SELECT-2")} color="success" sx={{ fontSize: "1.4rem" }} />
                )}
              </>
            )}

            <OrgBulkActions onSearch={onSearch} id={data.id} isActive={data.isSuspended} />
          </Box>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
