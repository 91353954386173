//** React imports */
import { lazy } from "react";
import AdminGuard from "guards/admin.guard";
import NonAdminGuard from "guards/nonAdmin.guard";

//** External Libraries */
import { Navigate, Route } from "react-router-dom";
//** Utils */
import { PrivateRoutes } from "models";
import { ErrorBoundary, RoutesWithNotFound } from "utilities";
import ErrorView from "pages/ErrorView";
import { UserContextProvider } from "pages/users";
import { OrganizationContextProvider } from "./context";

const OrganizationList = lazy(() => import("./pages/organizationList/OrganizationList"));
const OrganizationInformation = lazy(() => import("./pages/organizationsInformation/OrganizationsInformation"));
const OrganizationEdit = lazy(() => import("./pages/organizationEdit/OrganizationEdit"));
const OrganizationCreate = lazy(() => import("./pages/organizationsCreate/OrganizationsCreate"));
const LocationsClaim = lazy(() => import("../locations/pages/locationClaim/LocationClaim"));

export default function Organizations() {
  return (
    <ErrorBoundary fallBackComponent={<ErrorView />}>
      <OrganizationContextProvider>
        <UserContextProvider>
          <RoutesWithNotFound>
            <Route path="/" element={<Navigate to={PrivateRoutes.LIST} />} />
            <Route path={PrivateRoutes.CREATE} element={<OrganizationCreate />} />
            <Route element={<AdminGuard />}>
              <Route path={PrivateRoutes.LIST} element={<OrganizationList />} />
              <Route path={PrivateRoutes.INFORMATION} element={<OrganizationInformation />} />
              <Route path={PrivateRoutes.CLAIM_LOCATION} element={<LocationsClaim />} />
              <Route path={PrivateRoutes.EDIT} element={<OrganizationEdit />} />
            </Route>
            <Route element={<NonAdminGuard />}>
              <Route path={PrivateRoutes.CLAIM_LOCATION_NAV} element={<LocationsClaim />} />
              <Route path={PrivateRoutes.INFORMATION_NAV} element={<OrganizationInformation />} />
            </Route>
          </RoutesWithNotFound>
        </UserContextProvider>
      </OrganizationContextProvider>
    </ErrorBoundary>
  );
}
