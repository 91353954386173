/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import { Checkbox, Grid, List, ListItemButton, ListItemIcon, Skeleton, Theme, useMediaQuery, useTheme } from "@mui/material";
//** Models */
import { IUsersListDOM } from "pages/users/models";
import { PrivateRoutes } from "models";
//** Components */
import { Chip } from "components";
//** Hooks */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useTranslation } from "react-i18next";
import { useUsersListContext } from "pages/users/pages/usersList/context";
import { useTables } from "hooks";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useNavigate } from "react-router-dom";
import useEmptyCardsLocationData from "hooks/useEmptyCardsLocationData";
import { EmptyTableCards } from "pages/locations";

export default function UserDashboardItem({
  loading,
  hasNextPage,
  page,
  loadMoreUsers,
}: {
  loading: boolean;
  hasNextPage: boolean;
  page: number;
  loadMoreUsers: () => void;
}) {
  //** States */
  const [active, setActive] = useState<null | { type: string; id: string | number }>(null);
  const [hoverItem, setHoverItem] = useState<string>("");
  //** Hooks */
  const navigate = useNavigate();
  const { usersArray, selected, setSelected, statusFocus, clickedMarker, setClickedMarker } = useUsersListContext();
  const { hasFilter } = useListAndSearchContext();
  const themeMUI = useTheme();
  const { t } = useTranslation();
  const smAbove = useMediaQuery("(min-width:900px)");
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: page === 0 ? () => {} : () => loadMoreUsers(),
    rootMargin: "0px 0px 400px 0px",
  });

  const handleChatClick = (type: "user", id: string) => {
    setActive({ type, id });
  };

  const { dataUsersCards } = useEmptyCardsLocationData();

  const { handleClickTableCheckbox, isSelected, handleClickTableCheckboxMobile } = useTables(setSelected, selected, usersArray);
  const [scrolling, setScrolling] = useState(false);
  const [longPressTimer, setLongPressTimer] = useState<number | NodeJS.Timeout>(0);

  const handleTouchStart = (itemId: string) => {
    if (scrolling) return;
    setHoverItem(itemId);

    const timer = setTimeout(
      () => {
        // Handle long press here (e.g., item selection)
        handleClickTableCheckboxMobile(null, itemId);
      },
      selected.length > 0 ? 300 : 500,
    ); // Adjust the delay as needed for your long press threshold
    setLongPressTimer(timer);
  };

  const handleTouchMove = () => {
    if (!scrolling) {
      //When moving the user its scrolling
      setScrolling(true);
      //clear the timer (USER CAN'T SELECT WHILE SCROLLING)
      clearTimeout(longPressTimer);
    }
  };

  const handleTouchEnd = () => {
    setHoverItem("");
    // Clear the timer when the touch ends
    // Start a timer when the user touches an item
    setScrolling(false);
    clearTimeout(longPressTimer);
  };

  useEffect(() => {
    if (clickedMarker) {
      statusFocus?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center", //
      });
    }
    setClickedMarker(null);
  }, [clickedMarker]);

  if (usersArray.length === 0) {
    return (
      <Box sx={{ maxHeight: underMd ? "400px" : "calc(100vh - 580px)", minHeight: "300px", overflowY: "auto", maxWidth: "100%" }}>
        <Box textAlign="center" mx={8} my={16}>
          <Typography noWrap variant="h3" sx={{ color: "text.secondary" }} mb={4}>
            {hasFilter.length > 0 ? `${t("EMPTY-FILTER-USERS-TITLE")}` : `${t("EMPTY-USERS-TITLE")}`}
          </Typography>
          <Typography noWrap variant="h4" sx={{ color: "text.disabled" }} whiteSpace="normal">
            {hasFilter.length > 0 ? `${t("EMPTY-FILTER-USERS-DESCRIPTION")}` : `${t("EMPTY-USERS-DESCRIPTION")}`}
          </Typography>
        </Box>
        {hasFilter.length === 0 && (
          <Grid container spacing={4} justifyContent="center">
            {dataUsersCards.map((item, index) => (
              <EmptyTableCards key={index} data={dataUsersCards[index]} name="custom-radios-delivery" gridProps={{ xs: 10, mb: 8 }} />
            ))}
          </Grid>
        )}
      </Box>
    );
  }

  return (
    <Box ref={rootRef} sx={{ maxHeight: underMd ? "400px" : "calc(100vh - 580px)", overflowY: "auto", maxWidth: "100%" }}>
      <List>
        {usersArray.map((item: IUsersListDOM, index: number) => {
          const activeCondition = active !== null && active.id === item.id && active.type === "location";

          const isItemSelected = isSelected(item.id);
          const isItemHover = item.id === hoverItem;
          return (
            <ListItem
              key={index}
              disablePadding
              sx={{ "&:not(:last-child)": { mb: 1.5 } }}
              onClick={() => {
                navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.INFORMATION_NAV}/${item.id}`);
              }}
              ref={`${clickedMarker}` === item.id ? statusFocus : null}
              onMouseEnter={smAbove ? () => setHoverItem(item.id) : undefined}
              onMouseLeave={smAbove ? () => setHoverItem("") : undefined}
              onTouchStart={smAbove ? undefined : () => handleTouchStart(item.id)}
              onTouchEnd={smAbove ? undefined : () => handleTouchEnd()}
              onTouchMove={smAbove ? undefined : () => handleTouchMove()}
            >
              <ListItemButton
                disableRipple
                onClick={() => handleChatClick("user", item.id)}
                sx={{
                  px: 2.5,
                  py: 2.5,
                  width: "100%",
                  borderRadius: 1,
                  alignItems: "flex-start",
                  ...((activeCondition || isItemSelected) && {
                    backgroundColor: (theme) => `${isItemSelected ? theme.palette.primary.main : theme.palette.divider} !important`,
                  }),
                }}
              >
                <ListItemIcon onClick={(e) => e.stopPropagation()} sx={{ mr: 0 }}>
                  <Checkbox
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    color={themeMUI.palette.mode === "dark" ? "default" : "white"}
                    onClick={(event) => handleClickTableCheckbox(event, item.id)}
                    sx={
                      isItemHover || isItemSelected || selected.length > 0
                        ? { "& .MuiSvgIcon-root": { fontSize: 24 }, visibility: "visible", display: "block", p: 0, pl: 4 }
                        : { visibility: "hidden", display: "none" }
                    }
                    checked={isItemSelected}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    my: 0,
                    ml: 4,
                    mr: 1.5,
                    "& .MuiTypography-root": {
                      ...((activeCondition || isItemSelected) && {
                        color: `${themeMUI.palette.mode === "dark" ? "common.white" : isItemSelected ? "common.white" : "text.secondary"}`,
                      }),
                    },
                  }}
                  primary={
                    <Typography fontWeight={600} noWrap sx={{ ...(!activeCondition ? { color: "text.secondary" } : {}) }}>
                      {item.firstName} {item.lastName}
                    </Typography>
                  }
                  secondary={
                    <Box>
                      <Typography noWrap variant="body2" sx={{ ...(!activeCondition && { color: "text.disabled" }) }}>
                        {item.roleData[0].name}
                      </Typography>
                      <Typography noWrap variant="body2" sx={{ ...(!activeCondition && { color: "text.disabled" }) }}>
                        {item.username}
                      </Typography>
                    </Box>
                  }
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  {item.isSuspended ? (
                    <Chip size="small" label={t("OPTION-SELECT-1")} color="secondary" sx={{ fontSize: "1.4rem" }} />
                  ) : (
                    <Chip size="small" label={t("OPTION-SELECT-2")} color="success" sx={{ fontSize: "1.4rem" }} />
                  )}
                </Box>
              </ListItemButton>
            </ListItem>
          );
        })}
        {hasNextPage && (
          <ListItem ref={infiniteRef}>
            <Skeleton width="100%" height={80} />
          </ListItem>
        )}
      </List>
    </Box>
  );
}
